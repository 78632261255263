
     @import url("https://fastly.jsdelivr.net/npm/vant@4/lib/index.css");
     html,
		body {
			margin:0; padding:20px; width:100%; height:100%;
            -moz-user-select:none;/*火狐*/
            -webkit-user-select:none;/*webkit浏览器*/
            -ms-user-select:none;/*IE10*/
            -khtml-user-select:none;/*早期浏览器*/
            user-select:none;
		}
		#chat {
            // display: flex;
            // height: 100vh;
            // overflow: hidden;
            position:absolute;
            height:100%;
            width:100%;
            overflow: hidden;
            overflow-y: auto;
        }
        .chatBox {
			width: 100%;
            height: 100%;
			background-color: #fff;
            display: flex;
            flex-direction: column;
            // box-sizing: content-box;
			// border-radius: 0.625rem;
            .chatBox-top {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 0.5rem;
                height:3rem;
                background-color:#1989fa;
                .title{
                    color: #fff;
                    text-align: center;
                    width: 100%;
                }
            }
            .chatBox-middle {
                background-color: #fff;
                overflow: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                // flex:1;
                height: calc(100% - 12rem);
                .time-text{
                    font-size: 5rem;
                    color: #ff7f97;
                }
                .time-sub-text{
                    font-size:4rem;
                    color: #000;
                    margin-top: 0.8rem;
                    margin-left: 0.5rem;
                }
            }
            .chatBox-bottom {
                // height: 10rem;
                padding-top: 1rem;
                display: flex;
                justify-content: center;
                align-items:center ;
                padding:0 2rem;
            }
		}
